// https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Math/trunc
if (!Math.trunc) {
  Math.trunc = function (v) {
    v = +v;
    if (!isFinite(v)) return v;
    return v - (v % 1) || (v < 0 ? -0 : v === 0 ? v : 0);
  };
}
import 'core-js/stable/array/find-index';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
